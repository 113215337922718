<script>
export default {
    name: 'OrderList',
    props: {
        quantity: null,
        placeholder: null,
    },
}
</script>

<template lang="pug">
    .order-list-wrapper
        .subtitle-1.font-weight-medium.primary--text.d-flex.align-baseline.justify-space-between
            slot
            span(v-if="quantity") {{quantity}}
        .list
            slot(name="content")
            span(v-if="(!$slots.content || !$slots.content.length) && placeholder") {{placeholder}}


</template>

<style lang="scss" scoped></style>
