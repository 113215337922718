<script>
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'

import FormPanel from '@/components/form/FormPanel'
import FormThreeColRow from '@/components/form/FormThreeColRow'
import FormOneColRow from '@/components/form/FormOneColRow'
import FormTwoColRow from '@/components/form/FormTwoColRow'

import TextInput from '@/components/form/Inputs/TextInput'
import TextareaInput from '@/components/form/Inputs/TextareaInput'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'

import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'
import SelectApiClientType from '@/api/SelectApiClientType'
import Notification from '@/services/Notification/Notification'

export default {
    components: {
        FormPanel,
        FormThreeColRow,
        TextInput,
        TextareaInput,
        EntitySelectInput,
        FormTwoColRow,
        FormOneColRow,
    },

    mixins: [FormTabMixin, FormInput],

    inject: {
        $validator: 'formValidator',
    },

    data() {
        return {
            api: RestApiType.ORDERS,
            selectApiClientType: SelectApiClientType,
        }
    },

    computed: {
        disabled() {
            return (
                !this.model ||
                !this.model.refund ||
                !this.model.refund.amount ||
                this.model.refund.amount > parseFloat(this.model.priceSumUser)
            )
        },
    },

    methods: {
        sendRefund() {
            RestApiCollection.get(this.api)
                .refund(this.model.id, {
                    amount: this.model.refund.amount,
                    reason: this.model.refund.reason,
                    refundOnBehalf: this.model.refund.refundOnBehalf,
                })
                .then(() => {
                    Notification.success(
                        this.$t('notifications.refund_successful'),
                        ''
                    )
                })
                .catch((e) => {
                    if (e.response.status == 400) {
                        let error = e.response.data.errors['']
                        Notification.error(error, '')
                    }
                })
        },
    },
}
</script>

<template>
    <v-tab-item
        key="repayment_tab"
        :transition="false"
        :reverse-transition="false"
    >
        <form-panel
            v-if="model.refund"
            :title="$t('labels.repayment_settings')"
        >
            <form-three-col-row>
                <template v-slot:col1>
                    <text-input
                        v-model.number="model.refund.amount"
                        v-validate="'numeric'"
                        v-tab-error:repayment
                        name="amount"
                        label="labels.amount"
                        :error-messages="errors.collect('labels.amount')"
                        :data-vv-as="$t('labels.amount')"
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <text-input
                        v-model="model.refund.reason"
                        v-validate="'space'"
                        v-tab-error:repayment
                        name="reason"
                        label="labels.reason"
                        :error-messages="errors.collect('labels.reason')"
                        :data-vv-as="$t('labels.reason')"
                    ></text-input>
                </template>
                <template v-slot:col3>
                    <entity-select-input
                        v-model="model.refund.refundOnBehalf"
                        v-validate="'required'"
                        v-tab-error:repayment
                        label="labels.refund_on_behalf"
                        :data-vv-as="$t('labels.refund_on_behalf')"
                        :entity="selectApiClientType.ORDER_REFUND_BEHALF"
                        name="refundOnBehalf"
                        :error-messages="errors.collect('refundOnBehalf')"
                        translate-items
                    ></entity-select-input>
                </template>
            </form-three-col-row>
            <form-two-col-row>
                <template v-slot:col1>
                    <text-input
                        v-model="model.refund.processRefundId"
                        name="processRefundId"
                        label="labels.process_refund_id"
                        :disabled="true"
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <text-input
                        v-model="model.refund.status"
                        name="status"
                        label="labels.status"
                        :disabled="true"
                    ></text-input>
                </template>
            </form-two-col-row>
            <form-one-col-row>
                <textarea-input
                    v-model="model.refund.errorText"
                    name="errorText"
                    label="labels.error_text"
                    :disabled="true"
                ></textarea-input>
            </form-one-col-row>
        </form-panel>
        <portal v-if="$route.query.tab == 1" to="footer">
            <v-btn color="success" :disabled="disabled" @click="sendRefund">
                {{ $t('actions.save') }}
            </v-btn>
        </portal>
    </v-tab-item>
</template>
