<script>
import OrderItem from '@/components/pages/restaurantAdmin/OrderItem'
import { mapGetters } from 'vuex'
import authType from '@/store/type/authType'
import PrintLabel from '@/components/pages/restaurantAdmin/PrintLabel'
import PrintSummary from '@/components/pages/restaurantAdmin/PrintSummary'
import IdFromIri from '@/services/IdFromIri'

export default {
    name: 'HistoryOrder',
    components: {
        OrderItem,
        PrintLabel,
        PrintSummary,
    },
    props: {
        order: null,
    },
    computed: {
        ...mapGetters({
            isMultiRestaurantAdmin: authType.getters.IS_MULTI_RESTAURANT_ADMIN,
        }),
        open() {
            return this.order.identifier === this.$route.params.id
        },
        itemsCount() {
            return this.order?.meals?.reduce(
                (a, b) => Number(a) + Number(b.quantity),
                0
            )
        },
        cancelled() {
            return this.order.status === 'cancelled'
        },
        isCanteen() {
            return !this.order?.userProfile
        },
        restaurantId() {
            return IdFromIri.getId(this.order?.restaurant)
        },
    },
    methods: {
        toggle() {
            if (this.open)
                this.$router.replace({
                    params: { id: undefined },
                    query: this.$route.query,
                })
            else
                this.$router.replace({
                    params: { id: this.order.identifier },
                    query: this.$route.query,
                })
        },
    },
}
</script>

<template lang="pug">
.history-order.rounded-sm(v-if="order")
    .history-header.px-2(@click="toggle" :class="{'open': open}" role="button")
        v-row
            v-col
                .d-flex.flex-column.align-start
                    .text-h6.font-weight-bold {{'#'}}{{ order.identifierForRestaurant }}
                        template(v-if="isMultiRestaurantAdmin") -{{ order.restaurantName }}
                        v-chip.font-weight-bold.ml-2(v-if="cancelled" color="error" label small dark) {{ $t('enums.order_status.cancelled') }}
                    .text-body-2.g-73--text {{ order.deliveryName }}
                        span.ml-2(:inner-html.prop="order.requiredDeliveryTime | toSchedule")
            v-col(cols="auto")
                .d-flex.flex-column.align-end
                    .text-h6.font-weight-bold {{ order.priceSum | currency }}
                    .text-body-2.g-73--text {{ $tc('orders.items', itemsCount, { count: itemsCount }) }}
        .d-flex.justify-end.mb-3
            print-summary.mr-3(v-if="isCanteen" :order-identifier="order.identifier")
            print-label(v-if="isCanteen" :order="order" :restaurant-id="restaurantId")
    v-expand-transition
        v-container.order-item-wrapper.g-f3.py-0(v-show="open" fluid)
            order-item(v-for="(meal, index) in order.meals" :model="meal" :key="`orderHistoryMeal-${index}-${order.identifier}`")




</template>

<style scoped lang="scss">
.history-header {
    transition: background-color var(--transition-speed);
    max-width: var(--manage-order-width);
    &.open,
    &:hover {
        background-color: var(--v-g-ef-base);
    }
}
</style>
