<script>
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'

import FormTabs from '@/components/form/FormTabs'
import FormTab from '@/components/form/FormTab'
import GeneralTab from '@/components/pages/orders/tabs/General'
import RepaymentTab from '@/components/pages/orders/tabs/Repayment'

import FormBase from '@/components/mixins/FormBase'

export default {
    components: {
        FormTabs,
        FormTab,
        GeneralTab,
        RepaymentTab,
    },

    mixins: [FormBase],

    data() {
        return {
            api: RestApiType.ORDERS,
            mapper: MapperType.ORDER_FORM,
            form: { deliveryAddressDetails: {} },
            entity: 'labels.order',
            listRoute: routeType.ORDER_LIST,
            isValid: false,
        }
    },
    computed: {
        tabs() {
            return [
                { name: 'general', title: this.$t('labels.order_details') },
                {
                    name: 'repayment',
                    title: this.$t('labels.repayment'),
                    disabled:
                        !this.form.id || this.form.paymentMethod != 'stripe',
                },
            ]
        },
        activeTab: {
            get() {
                return parseInt(this.$route.query.tab) || 0
            },
            set(val) {
                this.$router.replace({ query: { tab: val } })
            },
        },
        title() {
            return this.form?.identifier ? this.form.identifier : null
        },
        deliveryJobId() {
            return this.form?.deliveryJob ? this.form.deliveryJob.id : null
        },
    },

    methods: {
        baseFormValid(value) {
            this.isValid = value
        },
    },
}
</script>

<template>
    <base-form
        ref="form"
        v-model="form"
        :api="api"
        :mapper="mapper"
        :entity-id="entityId"
        :title="title"
        :disabled="disabled"
        :disable-save-button="activeTab > 0"
        :disable-delete-button="true"
        @valid="baseFormValid($event)"
        @updated="updated"
        @cancelled="cancelled"
    >
        <template v-if="form">
            <form-tabs v-model="activeTab" class="pb-16">
                <form-tab
                    v-for="tab in tabs"
                    :key="tab.name"
                    :tab-errors="tabErrors"
                    :name="tab.name"
                    :title="tab.title"
                    :disabled="tab.disabled"
                ></form-tab>

                <v-tabs-items v-model="activeTab">
                    <general-tab
                        v-model="form"
                        :api="api"
                        :mapper="mapper"
                        :is-valid="isValid"
                        @tabError="tabErrorsContainer = $event"
                    ></general-tab>
                    <repayment-tab
                        v-model="form"
                        @tabError="tabErrorsContainer = $event"
                    ></repayment-tab>
                </v-tabs-items>
            </form-tabs>
        </template>
    </base-form>
</template>
