<script>
import MealRow from '@/components/pages/orders/tabs/general/MealRow'
import EditOrderModal from '@/components/pages/orders/tabs/general/dialog/EditOrderModal'
import EditButton from '@/components/table/buttons/EditButton'
import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'
import baseFormType from '@/store/type/baseFormType'
import MapperType from '@/services/mapper/MapperType'
import IdFromIri from '@/services/IdFromIri'
import { mapGetters } from 'vuex'
import canteenType from '@/store/type/canteenType'
export default {
    name: 'OrderDetailsBlock',
    components: { EditOrderModal, MealRow, EditButton },
    props: {
        model: Object,
        forCompany: Boolean,
    },
    data() {
        return {
            orderLastModified: {},
        }
    },
    computed: {
        ...mapGetters({
            canteen: canteenType.getters.CANTEEN,
        }),
        status() {
            return this.model?.status || 'init-payment'
        },
    },
    watch: {
        'model.id': {
            handler() {
                if (!this.forCompany) {
                    this.getLastModifyInfo()
                }
                this.getCanteen()
            },
        },
    },
    methods: {
        confirmOrder() {
            RestApiCollection.get(RestApiType.ORDERS)
                .approve(this.model.id)
                .then(() => {
                    this.$store.dispatch(
                        `baseForm/${baseFormType.actions.LOAD}`,
                        {
                            api: RestApiType.ORDERS,
                            id: this.model.id,
                            mapper: MapperType.ORDER_FORM,
                        }
                    )
                })
        },
        cancelOrder() {
            RestApiCollection.get(RestApiType.ORDERS)
                .cancel(this.model.id)
                .then(() => {
                    this.$store.dispatch(
                        `baseForm/${baseFormType.actions.LOAD}`,
                        {
                            api: RestApiType.ORDERS,
                            id: this.model.id,
                            mapper: MapperType.ORDER_FORM,
                        }
                    )
                })
        },
        getCanteen() {
            if (this.model?.cloudcanteen || this.model?.residentialcanteen) {
                RestApiCollection.get(RestApiType.CANTEENS).get(
                    IdFromIri.getId(this.model.restaurant),
                    this.model.cloudcanteen.identifier ||
                        this.model.residentialcanteen.identifier
                )
            }
        },
        getLastModifyInfo() {
            const exportParams = {
                limit: 1,
                property: 'status',
            }
            RestApiCollection.get(RestApiType.AUDITLOG)
                .get(this.model.id, 'Ordering', exportParams)
                .then((response) => {
                    const lastModifiedRow = response.slice(0, 1)
                    this.$set(this, 'orderLastModified', {
                        user: lastModifiedRow[0]?.blame_user || 'system',
                        createdAt: this.$date
                            .utc(lastModifiedRow[0].created_at)
                            .local()
                            .format('YYYY-MM-DD HH:mm:ss'),
                    })
                })
        },
    },
}
</script>

<template lang="pug">
    v-card.border-g-e8(flat)
        v-card-text.py-2
            .d-flex.align-center.flex-wrap.mb-1
                .text-h6.font-weight-bold {{$t('labels.order_details')}}
                edit-order-modal.ml-auto(v-if="!forCompany" :model="model")

            meal-row(
                v-for="(meal, index) in model.meals"
                :meal="meal"
                :currency="model.currency"
                :locale="model.currencyLocale"
                :key="`meal-${index}`")
            v-divider.my-2
            .d-flex.justify-space-between.text-body-1.g-26--text
                span {{$t('orders.subtotal')}}
                span {{ model.priceSumItems| currency(model.currency,model.currencyLocale) }}
            .d-flex.justify-space-between.text-body-1.g-26--text
                span {{$t('labels.delivery_fee')}}
                span {{ model.priceDelivery | currency(model.currency, model.currencyLocale) }}
            .d-flex.justify-space-between.text-body-1.g-26--text(v-if="model.firmDiscount")
                span {{$t('labels.discount')}}
                span -{{ model.firmDiscount | currency(model.currency, model.currencyLocale) }}
            .d-flex.justify-space-between.text-body-1.g-26--text(v-if="model.priceCouponDiscount")
                span {{$t('labels.coupon_discount')}}
                span -{{ model.priceCouponDiscount | currency(model.currency, model.currencyLocale) }}
            .d-flex.justify-space-between.text-body-1.g-26--text.font-weight-medium
                span {{$t('labels.total')}}
                span {{ model.priceSum | currency(model.currency, model.currencyLocale) }}
            v-divider.my-2
            .text-body-1.g-26--text.font-weight-medium.mb-2 {{$t('labels.payment')}}
            .d-flex.justify-space-between.text-body-1.g-26--text(v-if="model.priceSumFirm > 0")
                span {{$t('labels.paid_allowance')}}
                span {{ model.priceSumFirm | currency(model.currency, model.currencyLocale) }}
            template(v-if="model.priceSumUser > 0")
                .d-flex.justify-space-between.text-body-1.g-26--text(v-if="model.paymentMethod")
                    span {{ $t('orders.paid_by_method', {method: $t(`enums.payment_methods.${model.paymentMethod}`)}) }}
                    span {{ model.priceSumUser | currency(model.currency, model.currencyLocale) }}
                template(v-else-if="canteen")
                    .d-flex.justify-space-between.text-body-1.g-26--text(
                        v-for="(payment, index) in canteen.paymentSummary"
                        v-if="payment.paymentMethod"
                        :key="`canteenPayment-${index}`")
                        span(v-if="payment.paymentMethod") {{ $t('orders.paid_by_method', {method: $t(`enums.payment_methods.${payment.paymentMethod}`)}) }}
                        span(v-if="payment.paymentMethod") {{ payment.priceSumUser | currency(model.currency, model.currencyLocale) }}
            div(v-if="!forCompany")
                .d-flex.align-center.text-body-1.g-26--text.mt-4(v-if="model.costCode")
                    v-icon.mr-1(color="g-26" dense) receipt
                    | {{$t('labels.expense_code')}}: {{ model.costCode }}
                v-divider.my-2
                h3 {{$t('labels.order_status')}}:
                .d-flex.align-center
                    small {{$t('labels.last_modified')}}: {{ this.orderLastModified.user }} at {{ this.orderLastModified.createdAt }}
                .d-flex.align-center
                    h3 {{$t(`enums.order_status.${status}`)}}
                    .d-flex.ml-auto
                        edit-button(v-if="model.status !== 'approved'" :tooltip="$t('labels.approve_order')" icon='check_circle' @execute="confirmOrder")
                        edit-button.ml-2(v-if="model.status !== 'cancelled' && model.status !== 'cancelled-after-approval'" :tooltip="$t('labels.cancel_order')" icon='cancel' @execute="cancelOrder")
            slot

</template>

<style scoped lang="scss"></style>
