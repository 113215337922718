<template lang="pug">
    .request-driver
        v-btn.px-0(
            color="primary"
            :disabled="!model.canYouCreateProviderJob"
            @click="setDialog(true)" text x-small)
            span.text-subtitle-2 {{$t('labels.new_driver')}}
            v-icon(dense) chevron_right
        modal-dialog.request-driver-modal(
            v-model="dialog"
            :title="$t('labels.new_driver')"
            :show-overlay="loading"
            :ok-text="$t('actions.request')"
            :ok-loading="loading"
            @cancel="onCancel"
            @ok="createRequestNewDelivery"
            :ok-disabled="!deliveryMethod"
            cancel-button)
            template
                date-picker-input(v-model="pickupTime" label="orders.pickup_date_now" :min="$date().format()")
                label.text-h6.text-dense {{$t('labels.delivery_method')}}
                v-radio-group.mt-0(v-model="deliveryMethod" dense)
                    v-radio(
                        v-for="(method,index) in deliveryMethods"
                        :key="`DeliveryRule-${index}`"
                        :value="method"
                        :label="$t(method.label)")
                v-checkbox(v-model="noAutoCancel" :false-value="false" :label="$t('labels.turn_off_auto_cancel')")


</template>

<script>
import ModalDialog from '@/components/dialog/ModalDialog'
import RestApiCollection from '@/api/RestApiCollection'
import ApiClientType from '@/api/RestApiType'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'
import SelectApiClientType from '@/api/SelectApiClientType'
import selectapiType from '@/store/type/selectapiType'

const ALL = 'ALL'
const RESTAURANT_MANAGED = 'RESTAURANT_MANAGED'

export default {
    components: {
        DatePickerInput,
        ModalDialog,
    },
    props: {
        model: {
            type: Object,
            default: () => {},
        },
        identifier: null,
    },
    data() {
        return {
            dialog: false,
            loading: false,
            noAutoCancel: false,
            deliveryMethod: null,
            pickupTime: null,
            deliveryMethods: [],
            selectApiClientType: SelectApiClientType,
        }
    },

    methods: {
        async getMethods() {
            this.deliveryMethods = await this.$store.dispatch(
                selectapiType.getActionName(
                    this.selectApiClientType.DELIVERY_METHOD,
                    selectapiType.actions.LOAD
                )
            )
            this.deliveryMethods = this.deliveryMethods.filter(
                (method) => method.id !== RESTAURANT_MANAGED
            )
            this.deliveryMethods.unshift({
                id: 'ALL',
                key: this.deliveryMethods.reduce((a, b) => {
                    a.push(b.key)
                    return a
                }, []),
                label: this.deliveryMethods.reduce(
                    (a, b, index) =>
                        `${a}${index ? ', ' : ''}${this.$t(b.label)}${
                            index + 1 === this.deliveryMethods.length ? ']' : ''
                        }`,
                    `${this.$t(`enums.delivery_methods.all`)} [`
                ),
            })
            return true
        },
        createProviderJob(method, debounce = 0) {
            return new Promise((resolve) => {
                setTimeout(async () => {
                    await RestApiCollection.get(
                        ApiClientType.DELIVERIES
                    ).createAndQueueDeliveryProviderJob(
                        {
                            method: method,
                            disableAutoCancel: this.noAutoCancel,
                        },
                        this.model.id
                    )
                    resolve()
                }, 500 * debounce)
            })
        },
        async setDialog(value) {
            this.dialog = value
            if (this.dialog) {
                if (!this.deliveryMethods.length) await this.getMethods()
                this.deliveryMethod = this.deliveryMethods?.[0]?.value
                this.noAutoCancel = false
            }
        },
        onApprove() {
            this.setDialog(false)
            this.$emit('onApprove')
        },
        onCancel() {
            this.setDialog(false)
            this.$emit('onCancel')
        },
        createRequestNewDelivery() {
            this.loading = true
            RestApiCollection.get(ApiClientType.DELIVERIES)
                .updateDeliveryJob({
                    ...this.model,
                    ...{
                        pickupTime:
                            this.pickupTime ||
                            this.$date().add(1, 'm').format(),
                    },
                })
                .then(() => {
                    Promise.all(
                        this.deliveryMethod.id === ALL
                            ? this.deliveryMethod.key.map((method, index) =>
                                  this.createProviderJob(method, index)
                              )
                            : [this.createProviderJob(this.deliveryMethod.key)]
                    )
                        .then(async () => {
                            const result = await RestApiCollection.get(
                                ApiClientType.DELIVERIES
                            ).getProviderJob(this.identifier)
                            if (result['@id']) this.onApprove()
                            this.loading = false
                        })
                        .catch(() => {
                            this.loading = false
                        })
                })
                .catch(() => {
                    this.loading = false
                })
        },
    },
}
</script>
