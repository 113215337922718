<script>
export default {
    name: 'PreparationTimer',
    props: {
        order: Object,
        value: Boolean,
    },
    data() {
        return {
            time: 0,
            timer: null,
        }
    },
    computed: {
        deliveryByYou() {
            return !(
                this.order?.deliveryJob &&
                this.order?.deliveryJob.primaryDeliveryMethod !==
                    'restaurant_managed'
            )
        },
        deadline() {
            return this.deliveryByYou
                ? this.order?.startDeliveryAt
                : this.order?.deliveryJob?.etaToPickup ||
                      this.order?.deliveryJob?.pickupTime ||
                      this.order?.startDeliveryAt
        },
        max() {
            return this.$date(this.deadline).diff(
                this.order.startPrepareAt,
                'minute'
            )
        },
        progress() {
            return ((this.max - this.time) / this.max) * 100
        },
    },
    mounted() {
        this.calcTime()
        this.startClock()
    },
    beforeDestroy() {
        this.stopClock()
    },
    methods: {
        startClock() {
            this.timer = setInterval(this.calcTime, 60000)
        },
        stopClock() {
            clearInterval(this.timer)
        },
        calcTime() {
            if (this.deadline) {
                this.time = this.$date(this.deadline).diff(
                    this.$date(),
                    'minute'
                )
            }
        },
    },
}
</script>

<template lang="pug">
v-progress-circular.preparation-timer(
    :rotate="-90"
    :size="50"
    :width="2"
    :value="progress"
    :class="{'ending-soon': time < 10 && time > 0}"
    :color="time > 0 ? 'primary' : 'info'")
    .preparation-time
        template(v-if="time > 0")
            span.font-weight-bold {{ time }}
            small.font-weight-regular.text-uppercase {{ $t('labels.min') }}
        template(v-else)
            v-icon(large color="g-a1") motorcycle

</template>

<style lang="scss">
.preparation-timer {
    .preparation-time {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: var(--v-g-a1-base);
        line-height: 1;
    }
    &.ending-soon {
        animation: ending 1s infinite alternate both;
    }
    @keyframes ending {
        from {
            transform: scale(1);
        }
        to {
            transform: scale(1.2);
        }
    }
}
</style>
