<script>
import PreparationTimer from '@/components/pages/restaurantAdmin/PreparationTimer'
import authType from '@/store/type/authType'
import { mapGetters } from 'vuex'
export default {
    name: 'OrderListItem',
    components: { PreparationTimer },
    props: {
        order: null,
        active: Boolean,
    },
    data() {
        return {
            expired: false,
        }
    },
    computed: {
        ...mapGetters({
            isMultiRestaurantAdmin: authType.getters.IS_MULTI_RESTAURANT_ADMIN,
        }),
        status() {
            return this.order
        },
        deliveryByYou() {
            return !(
                this.order?.deliveryJob &&
                this.order?.deliveryJob.primaryDeliveryMethod !==
                    'restaurant_managed'
            )
        },
        isPickup() {
            return this.order?.isPickup
        },
        isCanteen() {
            return !this.order?.userProfile
        },
        isScheduled() {
            return (
                !this.order.preparationConfirmed &&
                !this.order.isAsap &&
                !this.isCanteen
            )
        },
        isNew() {
            return this.status.key === 'new' && !this.open
        },
        isPreparing() {
            return this.order.preparationConfirmed
        },
        itemsCount() {
            return this.isOpen
                ? this.order?.mealCount
                : this.order?.mealsQuantity
        },
        price() {
            return this.isOpen ? this.order.total : this.order?.priceTotal
        },
        isOpen() {
            return Boolean(this.order.cutoffTime)
        },
    },
}
</script>

<template lang="pug">
    router-link.order-list-item.d-flex.justify-space-between(
        v-if="order && !expired"
        tag="div"
        :to="{params: {id: isOpen ? order.identifier : order.id, restaurant: isOpen ? order.restaurantId : undefined}}"
        active-class="active")
        .d-flex.flex-column.justify-center
            .d-flex.align-start.flex-wrap
                span.font-weight-medium.text-body-2.mr-1 {{'#'}}{{order.identifierForRestaurant}}
                v-chip.font-weight-bold(v-if="isPickup && !isScheduled && !isOpen" color="blue" label small dark) {{ $t('orders.pickup_by_stuart') }}
                v-chip.font-weight-bold(v-if="deliveryByYou && !isScheduled && !isOpen" color="blue" label small dark) {{ $t('orders.delivery_by_you') }}
                v-chip.font-weight-bold(v-if="isScheduled && !isOpen" color="warning" label small dark) {{ $t('orders.scheduled') }}
                .font-weight-medium.text-body-2.mr-1.w-100(v-if="isMultiRestaurantAdmin") {{order.restaurantName}}
            .d-flex.align-center.flex-wrap
                span.font-weight-medium.text-body-2(v-if="itemsCount" :class="{'mr-2': !isOpen}") {{ $tc('orders.items', itemsCount, { count: itemsCount }) }}
                    template(v-if="isOpen") &nbsp;{{ $t('orders.so_far') }}
                .flex-break(v-if="isOpen")
                span.text-body-2.g-73--text {{ price | currency}}
        .d-flex.justify-end.ml-1(:class="isOpen ? 'align-start' : 'align-center'")
            preparation-timer(v-if="isPreparing" v-model="expired" :order="order")
            template(v-if="isScheduled")
                template(v-if="!isOpen")
                    template(v-if="deliveryByYou")
                        .d-flex.flex-column.align-end.justify-center.text-body-2.g-73--text(:inner-html.prop="order.requiredDeliveryTime | toSchedule")
                    template(v-else)
                        .d-flex.flex-column.align-end.justify-center.text-body-2.g-73--text(:inner-html.prop="order.startDeliveryAt | toSchedule")
                .d-flex.flex-column(v-else)
                    |  {{ $t('orders.cutoff')}}
                    .d-flex.flex-column.align-end.justify-center.text-body-2.g-73--text(:inner-html.prop="order.cutoffTime | toSchedule")

</template>

<style lang="scss">
.order-list-item {
    border-top: 1px solid var(--v-g-cc-base);
    padding: 15px 10px;
    transition: background-color var(--transition-speed);
    &.active,
    &:hover {
        background-color: var(--v-g-f0-base);
    }
}
</style>
