<template>
    <gmap-map
        ref="map"
        :center="defaultCenter"
        :zoom="zoom"
        :options="options"
        class="input-map-frame"
    >
        <gmap-marker
            v-for="(m, index) in markers"
            :key="index"
            :position="m.position"
            :clickable="true"
            :draggable="false"
            :title="m.title"
            :icon="m.icon"
            :z-index="m.zindex"
        />
    </gmap-map>
</template>

<script>
import { mapGetters } from 'vuex'
import configType from '@/store/type/configType'
import GoogleMapAddressMixin from '../InputMixins/GoogleMapAddressMixin'

export default {
    mixins: [GoogleMapAddressMixin],
    props: {
        zoom: {
            type: Number,
            default: 16,
        },
        draggable: {
            type: Boolean,
            default: true,
        },
        options: {
            type: Object,
            default() {
                return {
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                    maxZoom: 16,
                }
            },
        },
        updateAddressDetails: {
            type: Boolean,
        },
        name: {
            type: String,
            default: '',
        },
        addresses: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            markers: [],
            isReady: false,
        }
    },
    computed: {
        ...mapGetters({
            defaultCenter: configType.getters.GOOGLE_MAPS_DEFAULT_CENTER,
        }),
        point() {
            return {
                lat:
                    this.latitude == null
                        ? this.defaultCenter.lat
                        : this.latitude,
                lng:
                    this.longitude == null
                        ? this.defaultCenter.lng
                        : this.longitude,
            }
        },
        hasAddress() {
            return (
                this.addresses !== null &&
                this.addresses.length > 0 &&
                this.addresses[0].address !== null &&
                this.addresses[0].address !== ''
            )
        },
    },
    watch: {
        isReady(value) {
            if (value && this.hasAddress) this.getMarkers()
        },

        markers(markers) {
            if (this.isReady && markers?.length > 1) this.setCenterMap()
        },

        addresses() {
            if (this.isReady && this.hasAddress) this.getMarkers()
        },
    },
    async mounted() {
        await this.$gmapApiPromiseLazy()
        this.$geocoder = new google.maps.Geocoder()
        this.isReady = true
    },
    methods: {
        markerDragEnd(event) {
            this.updateAddress({
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            })
        },
        setCenterMap() {
            const bounds = new google.maps.LatLngBounds()
            const markers = this.markers.filter((marker) => {
                return marker.focused || marker.focused === undefined
            })
            markers.sort((a, b) => a.position?.lng - b.position?.lng)
            markers.forEach((marker) => {
                bounds.extend(marker.position)
            })

            this.$refs.map.$mapPromise.then((map) => {
                map.setCenter(bounds.getCenter())
                map.fitBounds(bounds)
                map.setZoom(map.getZoom())
            })
        },
        getMarkers() {
            if (this.isReady) {
                const addresses = this.addresses
                this.markers = []
                addresses.forEach((address) => {
                    if (address.lat && address.lng) {
                        this.pushMarker(address)
                    } else if (address.address) {
                        this.$geocoder
                            .geocode(
                                { address: address.address },
                                (results, status) => {
                                    if (
                                        status == google.maps.GeocoderStatus.OK
                                    ) {
                                        address.lat = results[0].geometry.location.lat()
                                        address.lng = results[0].geometry.location.lng()
                                    }
                                }
                            )
                            .then(() => {
                                this.pushMarker(address)
                            })
                            .catch((reason) => {
                                throw reason
                            })
                    }
                })
            }
        },
        pushMarker(address) {
            this.markers.push({
                position: {
                    lat: address.lat,
                    lng: address.lng,
                    address: address.address,
                },
                title: address.title,
                zindex: address.zindex,
                focused: address.focused,
                icon: this.getSiteIcon(address['icon']), // if you want to show different as per the condition.
            })
        },
        getSiteIcon(icon) {
            try {
                switch (icon) {
                    case 'restaurant':
                        return '/img/marker-pickup.svg'

                    case 'dropoff':
                        return '/img/marker-dropoff.svg'

                    case 'courier':
                        return '/img/marker-courier.svg'

                    default:
                        return '/img/marker-pickup.svg'
                }
            } catch (e) {
                return null
            }
        },
    },
}
</script>
