<script>
import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'
import restaurantType from '@/store/type/restaurantType'
import canteenType from '@/store/type/canteenType'
import { mapGetters } from 'vuex'
import OrderList from '@/components/pages/restaurantAdmin/OrderList'
import OrderListItem from '@/components/pages/restaurantAdmin/OrderListItem'
import OpenOrder from '@/components/pages/restaurantAdmin/OpenOrder'

export default {
    name: 'Open',
    components: { OpenOrder, OrderListItem, OrderList },
    computed: {
        ...mapGetters({
            list: canteenType.getters.CANTEEN_LIST,
            model: canteenType.getters.CANTEEN,
            restaurant: restaurantType.getters.ACTIVE_RESTAURANT,
        }),
    },
    mounted() {
        if (
            !this.$route.params.id &&
            this.list &&
            Array.isArray(this.list[0])
        ) {
            this.$router.replace({ params: { id: this.list[0].identifier } })
        }
    },
    beforeRouteEnter(to, from, next) {
        RestApiCollection.get(RestApiType.CANTEENS)
            .list()
            .then(() => {
                next()
            })
            .catch(() => {
                next({ name: 'not-found' })
            })
    },
}
</script>

<template lang="pug">
v-row
    v-col(cols="12")
        h3.mt-4 {{ $t('labels.open_orders_preview') }}
        p(v-html="$t('texts.open_orders')")
    v-col(cols="12" md="3" lg="4" xl="3")
        v-card(:elevation="0")
            v-card-text
                order-list(:quantity="list.length" :placeholder="$t('orders.no_item')") {{ $t('labels.open_orders') }}
                    template(slot="content")
                        order-list-item(v-for="(item, index) in list" :order="item" :key="`list-item-open-${index}`")
    v-col(cols="auto").d-none.d-md-block
    v-col
        open-order(:order="model")
</template>

<style scoped lang="scss"></style>
