<script>
import RequestDriverModal from '@/components/pages/orders/tabs/general/dialog/RequestDriverModal'
import { mapGetters } from 'vuex'
import deliveryType from '@/store/type/deliveryType'
import AddressMultipleMapInput from '@/components/form/Inputs/google/AddressMultipleMapInput'
import EditButton from '@/components/table/buttons/EditButton'
import RestApiCollection from '@/api/RestApiCollection'
import configType from '@/store/type/deliveryType'
export default {
    name: 'DeliveryStatusBlock',
    components: { EditButton, AddressMultipleMapInput, RequestDriverModal },
    props: {
        model: Object,
        api: String,
    },
    data() {
        return {
            cancelProviderLoading: [],
            sendProviderLoading: [],
            forceFinishLoading: false,
            forceFinishSuccessful: false,
        }
    },
    computed: {
        ...mapGetters({
            providerJobs: deliveryType.getters.PROVIDER_JOBS,
            deliveryJob: deliveryType.getters.DELIVERY_JOB,
        }),
        fullView() {
            return !this.isPicking && !this.isDelivering
        },
        isPicking() {
            return ['picking', 'almost_picking', 'waiting_at_pickup'].includes(
                this.deliveryJob?.courierStatus
            )
        },
        isDelivering() {
            return [
                'delivering',
                'almost_delivering',
                'waiting_at_dropoff',
            ].includes(this.deliveryJob?.courierStatus)
        },
        visibleCourierIcon() {
            return this.isDelivering || this.isPicking
        },
        markers() {
            return [
                {
                    address:
                        this.deliveryJob?.pickupAddress?.full_address || '',
                    icon: 'restaurant',
                    lat: this.deliveryJob?.pickupAddress?.latitude || null,
                    lng: this.deliveryJob?.pickupAddress?.longitude || null,
                    zindex: 1,
                    focused: this.isPicking || this.fullView,
                    title: this.$t('labels.position_restaurant'),
                },
                {
                    address: null,
                    icon: 'courier',
                    lat: this.visibleCourierIcon
                        ? this.deliveryJob.courierLat
                        : undefined,
                    lng: this.visibleCourierIcon
                        ? this.deliveryJob.courierLong
                        : undefined,
                    zindex: 3,
                    focused: this.visibleCourierIcon,
                    title: this.$t('labels.position_courier'),
                },
                {
                    address:
                        this.deliveryJob?.dropoffAddress?.full_address || '',
                    icon: 'dropoff',
                    lat: this.deliveryJob?.dropoffAddress?.latitude || null,
                    lng: this.deliveryJob?.dropoffAddress?.longitude || null,
                    zindex: 2,
                    focused: this.isDelivering || this.fullView,
                    title: this.$t('labels.position_dropoff'),
                },
            ]
            //lat: 51.5091943, lng: -0.0783677
            //lat:51.51914240000001, lng: -0.1503294
        },
        etaToPickup() {
            return this.deliveryJob?.etaToPickup
                ? Math.trunc(
                      this.$date
                          .duration(
                              this.$date(
                                  this.deliveryJob?.etaToPickup
                              ).valueOf() - this.$date().valueOf()
                          )
                          .asMinutes()
                  )
                : undefined
        },
        etaToDropoff() {
            return this.deliveryJob?.etaToDropoff
                ? Math.trunc(
                      this.$date
                          .duration(
                              this.$date(
                                  this.deliveryJob?.etaToDropoff
                              ).valueOf() - this.$date().valueOf()
                          )
                          .asMinutes()
                  )
                : undefined
        },
        pickupTime() {
            return this.$date(this.deliveryJob.pickupTime)
                .subtract(1, 'hours')
                .format('HH:mm')
        },
    },

    methods: {
        cancelProviderJob(id) {
            this.cancelProviderLoading.push(id)
            RestApiCollection.get(this.api)
                .cancelDeliveryProviderJob(id)
                .then((res) => {
                    if (res.success)
                        this.$store.dispatch(
                            configType.actions.CANCEL_PROVIDER_JOB,
                            id
                        )
                })
                .finally(() => {
                    let index = this.cancelProviderLoading.indexOf(id)
                    if (index >= 0) this.cancelProviderLoading.splice(index, 1)
                })
        },
        sendProviderJob(id) {
            this.sendProviderLoading.push(id)
            RestApiCollection.get(this.api)
                .sendDeliveryProviderJob(id)
                .finally(() => {
                    let index = this.sendProviderLoading.indexOf(id)
                    if (index >= 0) this.sendProviderLoading.splice(index, 1)
                })
        },
        forceFinishDeliveryJob() {
            this.forceFinishLoading = true
            RestApiCollection.get(this.api)
                .forceFinishDeliveryJob(this.deliveryJob.id)
                .then((res) => {
                    if (res.success) this.forceFinishSuccessful = true
                })
                .finally(() => {
                    this.forceFinishLoading = false
                })
        },
    },
}
</script>

<template lang="pug">
    v-card.border-g-e8(v-if="model && deliveryJob" flat)
        v-card-text.py-2
            v-row(justify="space-between")
                v-col.py-0(cols="auto")
                    .text-h6.font-weight-bold
                        template(v-if="deliveryJob.status === 'initialized'") {{$t(`delivery_job_status.initialized`)}}
                        template(v-else-if="deliveryJob.status === 'queued'") {{$t(`delivery_job_status_detailed.queued`, {date: pickupTime})}}
                        template(v-else-if="['picking','almost_picking'].includes(deliveryJob.courierStatus)") {{$t(`${etaToPickup ? 'courier_status_detailed' : 'courier_status'}.${deliveryJob.courierStatus}`, {pickup: etaToPickup})}}
                        template(v-else-if="['delivering','almost_delivering'].includes(deliveryJob.courierStatus)") {{$t(`${etaToDropoff ? 'courier_status_detailed' : 'courier_status'}.${deliveryJob.courierStatus}`, {dropoff: etaToDropoff})}}
                        template(v-else-if="deliveryJob.courierStatus") {{$t(`courier_status_detailed.${deliveryJob.courierStatus}`)}}
                v-col.py-0(v-if="(deliveryJob.status === 'cancelled' || deliveryJob.status === 'error') && !forceFinishSuccessful" cols="auto")
                    edit-button(
                        :tooltip="$t('labels.force_finish_delivery')"
                        :loading="forceFinishLoading"
                        icon='double_arrow'
                        @execute="forceFinishDeliveryJob()")
            .py-3(v-if="deliveryJob")
                template(v-if="deliveryJob.driverName") {{ deliveryJob.driverName }} |&nbsp;
                template(v-if="deliveryJob.activeDeliveryMethod") {{ deliveryJob.activeDeliveryMethod }} |&nbsp;
                | {{ deliveryJob.driverPhone }}
            address-multiple-map-input(
                style="height: 200px"
                name="deliveryFullAddressMap"
                :addresses="markers"
                :draggable="false")
            .d-flex.align-center.flex-wra.mb-1.mb-1
                .text-h6.font-weight-bold {{$t('labels.drivers')}}
                request-driver-modal.ml-auto(:model="deliveryJob" :identifier="model.identifier")
            .d-flex.flex-column-reverse
                .provider-job(v-for="(data, index) in providerJobs" :key="`providerJob-${index}`")
                    v-row(no-gutters)
                        v-col.py-2.pr-2(cols="5")
                            .text-body-1.g-26--text.text-capitalize.line-1 {{ data.deliveryMethod }}
                            .text-body-2.text-ellipsis(v-if="data.deliveryUid") \#{{data.deliveryUid }}
                        v-col.py-2.pr-2(cols)
                            .text-body-2
                                template(v-if="data.status === 'in_progress' && data.courierStatus") {{ $t(`courier_status.${data.courierStatus}`)}}
                                template(v-else) {{ $t(`delivery_job_status.${data.status}`)}}
                            .text-body-2 {{data.updatedAt | transformDate}}
                        v-col.py-2(
                            cols="auto"
                            v-if="['initialized', 'queued'].includes(data.status) || (data.status === 'in_progress' && data.courierStatus === 'picking')")
                            edit-button(
                                :tooltip="$t('labels.cancel_provider_job')"
                                :loading="cancelProviderLoading.includes(data.id)"
                                icon='cancel'
                                @execute="cancelProviderJob(data.id)")
                        v-col.py-2(
                            cols="auto"
                            v-if="data.status === 'queued' && ['onfleet', 'onfleet_pedivan'].includes(data.deliveryMethod)")
                            edit-button(
                                :tooltip="$t('labels.send_provider_job')"
                                :loading="sendProviderLoading.includes(data.id)"
                                icon='send'
                                @execute="sendProviderJob(data.id)")
                    v-divider.my-0.py-0(v-if="index")
</template>
