<script>
export default {
    name: 'MealRow',
    props: {
        meal: Object,
        currency: null,
        locale: null,
    },
}
</script>

<template lang="pug">
.meal-row
    v-row.font-weight-medium.text-body-1.g-26--text(no-gutters)
        v-col(cols="1") {{ meal.quantity }}x
        v-col(cols="11").d-flex.justify-space-between
            span(v-html="meal.name")
            span {{ (meal.priceWithMealOptions * meal.quantity) | currency(currency, locale) }}
    v-row.text-body-2.g-73--text(v-for="(option, index) in meal.children" :key="`mealOption-${index}`" no-gutters)
        v-col.d-flex.justify-space-between(offset="1" cols="11")
            span(v-html="option.name")
            span(v-if="option.priceWithMealOptions") {{ (option.priceWithMealOptions * option.quantity) | currency(currency, locale) }}
            span(v-else) --

</template>

<style scoped lang="scss"></style>
