<script>
import { mapGetters } from 'vuex'
import deliveryType from '@/store/type/deliveryType'
import EditDeliveryModal from '@/components/pages/orders/tabs/general/dialog/EditDeliveryModal'
import restaurantType from '@/store/type/restaurantType'

export default {
    name: 'DeliveryDetailsBlock',
    components: { EditDeliveryModal },
    props: {
        model: Object,
        mapper: {
            type: String,
            required: true,
        },
        api: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            deliveryJob: deliveryType.getters.DELIVERY_JOB,
            restaurant: restaurantType.getters.RESTAURANT,
        }),
        pickupAddress() {
            return this.deliveryJob?.pickupAddress || {}
        },
        dropoffAddress() {
            return this.deliveryJob?.dropoffAddress || {}
        },
    },
}
</script>

<template lang="pug">
    v-card.border-g-e8(flat)
        v-card-text.py-2
            .d-flex.align-center.flex-wra.mb-1
                .text-h6.font-weight-bold {{$t('labels.delivery_details')}}
                edit-delivery-modal.ml-auto(
                    :model="model"
                    :delivery-job="deliveryJob"
                    :restaurant="restaurant"
                    :api="api"
                    :mapper="mapper")
            .mb-1.body-1.font-weight-medium
                v-icon(color="g-26") room
                span.g-26--text {{$t('orders.pickup')}}&nbsp;
                span.g-26--text(v-if="deliveryJob") {{ deliveryJob.pickupTime || model.startDeliveryAt | transformDate }}
            template
                .mb-1.body-2.g-26--text {{ pickupAddress.contact_company || model.restaurantName }}
                .mb-1.body-2.g-73--text(v-if="pickupAddress.full_address || restaurant") {{ pickupAddress.full_address || restaurant.location }}
                .mb-1.body-2.g-73--text
                    template(v-if="pickupAddress.contact_first_name || pickupAddress.contact_last_name")
                        | {{ pickupAddress.contact_first_name }}&nbsp;
                        | {{ pickupAddress.contact_last_name || '-' }}&nbsp;|&nbsp;
                    template(v-if="pickupAddress.contact_phone || restaurant") {{ pickupAddress.contact_phone || restaurant.phone || '-' }}
            .d-flex.align-start.mb-1.body-2.g-73--text(v-if="deliveryJob.pickupComment")
                v-icon.mr-2(color="g-e8") format_quote
                | {{ deliveryJob.pickupComment }}
            v-divider.my-2
            .mb-1.body-1.font-weight-medium
                v-icon(color="g-26") flag
                span.g-26--text {{$t('labels.delivery')}}&nbsp;
                span.g-26--text {{ model.requiredDeliveryTime | transformDate }}
            template
                .mb-1.body-2.g-26--text {{ dropoffAddress.contact_company || model.companyName }}
                .mb-1.body-2.g-73--text {{ dropoffAddress.full_address || model.deliveryFullAddress }}
                .mb-1.body-2.g-73--text {{ model.deliverySubAddress }}
                .mb-1.body-2.g-73--text
                    template(v-if="dropoffAddress.contact_first_name || dropoffAddress.contact_last_name")
                        | {{ dropoffAddress.contact_first_name }}&nbsp;
                        | {{ dropoffAddress.contact_last_name || '-' }}&nbsp;|&nbsp;
                    template(v-else) {{ model.deliveryName }}&nbsp;|&nbsp;
                    | {{ dropoffAddress.contact_phone || model.phone || '-' }}
            .d-flex.align-start.mb-1.body-2.g-73--text(v-if="deliveryJob.dropOffComment").alig
                v-icon.mr-2(color="g-e8") format_quote
                | {{ deliveryJob.dropOffComment }}
</template>
