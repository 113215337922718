import Event from '@/mixins/Event'
import { mapGetters } from 'vuex'
import deliveryType from '@/store/type/deliveryType'
import configType from '@/store/type/deliveryType'
import cloneDeep from 'lodash/cloneDeep'
export default {
    mixins: [Event],
    data() {
        return {
            channelOrder: null,
            channelDeliveryJobs: null,
            channelDeliveryProviderJobs: null,
            logToConsole: false,
        }
    },

    computed: {
        ...mapGetters({
            providerJobs: deliveryType.getters.PROVIDER_JOBS,
            deliveryJob: deliveryType.getters.DELIVERY_JOB,
        }),
    },

    created() {
        this.channelOrder = this.pusher.subscribe('orders')
        this.channelDeliveryJobs = this.pusher.subscribe('delivery-jobs')
        this.channelDeliveryProviderJobs = this.pusher.subscribe(
            'delivery-provider-jobs'
        )
        this.channelOrder.bind('update-order', this.updateOrder)

        this.channelDeliveryJobs.bind(
            'update-delivery-job',
            this.updateDeliveryJob
        )

        this.channelDeliveryProviderJobs.bind(
            'update-delivery-provider-job',
            this.updateDeliveryProviderJob
        )
    },
    beforeDestroy() {
        if (this.pusher) {
            // this.channelOrder.unbind()
            // this.channelDeliveryJobs.unbind()
            // this.channelDeliveryProviderJobs.unbind()
            // this.pusher.unsubscribe('orders')
            // this.pusher.unsubscribe('delivery-jobs')
            // this.pusher.unsubscribe('delivery-provider-jobs')
            this.pusher.disconnect()
        }
    },
    methods: {
        updateOrder(data) {
            if (data && data.id === this.model.id) {
                this.$set(
                    this,
                    'model',
                    this.objectMapper(this.model, data.data, this.mapOrder)
                )
            }
        },
        updateDeliveryJob(data) {
            if (data && data.id === this.deliveryJob.id) {
                this.$store.commit(
                    configType.mutations.SET_DELIVERY_JOB,
                    this.objectMapper(
                        this.deliveryJob,
                        data.data,
                        this.mapDeliveryJob
                    )
                )
            }
        },
        updateDeliveryProviderJob(data) {
            let index = this.providerJobs
                ? this.providerJobs?.findIndex((job) => job.id === data.id)
                : -1
            if (index >= 0) {
                let providerJobs = cloneDeep(this.providerJobs)
                providerJobs[index] = this.objectMapper(
                    providerJobs[index],
                    data.data,
                    this.mapDeliveryProviderJob
                )
                this.$store.commit(
                    configType.mutations.SET_PROVIDER_JOBS,
                    providerJobs
                )
            }
        },
    },
}
