<script>
import SelectApiClientType from '@/api/SelectApiClientType'
import FormPanel from '@/components/form/FormPanel'
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'
import OrderSoftwareSending from '@/components/pages/orders/OrditSoftwareSending'
import ApiClientType from '@/api/RestApiType'
import RestApiCollection from '@/api/RestApiCollection'
import IdFromIri from '@/services/IdFromIri'
import DeliveryStatusBlock from '@/components/pages/orders/tabs/general/DeliveryStatusBlock'
import deliveryType from '@/store/type/deliveryType'
import restaurantType from '@/store/type/restaurantType'
import DeliveryDetailsBlock from '@/components/pages/orders/tabs/general/DeliveryDetailsBlock'
import OrderDetailsBlock from '@/components/pages/orders/tabs/general/OrderDetailsBlock'
import TextareaInput from '@/components/form/Inputs/TextareaInput'
import OrderGeneralTabEvent from '@/pages/orders/mixins/OrderGeneralTabEvent'
import { mapGetters, mapActions } from 'vuex'

export default {
    components: {
        TextareaInput,
        OrderDetailsBlock,
        DeliveryDetailsBlock,
        DeliveryStatusBlock,
        FormPanel,
        OrderSoftwareSending,
    },
    mixins: [FormTabMixin, FormInput, OrderGeneralTabEvent],
    props: {
        isValid: {
            type: Boolean,
        },
        mapper: {
            type: String,
            required: true,
        },
        api: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            apiDeliveries: ApiClientType.DELIVERIES,
            selectApiClientType: SelectApiClientType,
        }
    },

    computed: {
        ...mapGetters({
            busy: `${deliveryType.getters.BUSY}`,
        }),

        warnings() {
            let warnings = this.model.warning
                ? this.model.warnings
                : this.model?.deliveryJob?.warnings
            if (typeof warnings === 'string') warnings = JSON.parse(warnings)
            return warnings || []
        },
    },

    watch: {
        'model.deliveryJob': {
            immediate: true,
            handler(val) {
                RestApiCollection.get(this.apiDeliveries).getDeliveryJob(
                    val?.id
                )
                RestApiCollection.get(this.apiDeliveries).getProviderJob(
                    this.model?.identifier
                )
            },
        },
        'model.restaurant': {
            immediate: true,
            handler(val) {
                if (val == null) return
                this.getRestaurant(IdFromIri.getId(val))
            },
        },
    },

    methods: {
        ...mapActions({
            getRestaurant: restaurantType.actions.GET_RESTAURANT,
        }),
        setSoftwareSending(val) {
            const idx = this.model.softwareSendings.findIndex((el) => {
                return el['@id'] == val['@id']
            })
            if (idx !== -1) this.$set(this.model.softwareSendings, idx, val)
        },
    },
}
</script>

<template lang="pug">
    v-tab-item(key="general_tab" :transition="false" :reverse-transition="false")
        .pt-5
            v-chip.rounded.box-label.mb-1.mr-2.white--text(v-for='warning in warnings' :key="`orderWarning-${warning}`" small color='red') {{ $t(`delivery_warning.${warning}`) }}
        v-row.pt-2
            v-col(v-if="model && model.deliveryJob" cols="12" sm="4")
                delivery-status-block(:model="model" :api="apiDeliveries")
            v-col(cols="12" sm="4")
                delivery-details-block(:model="model" :api="api" :mapper="mapper")
            v-col(cols="12" sm="4")
                order-details-block(:model="model")

        form-panel.mb-4(:title="$t('labels.admin_note')")
            // ADMIN NOTE
            textarea-input(v-model="model.note" v-validate.immediate="'space'" name="admin_note" label="labels.admin_note" :data-vv-as="$t('labels.admin_note')" :error-messages="errors.collect('admin_note')")

        form-panel.mb-4(v-if="model.softwareSendings && model.softwareSendings.length" :title="$t('labels.software_sendings')")
            // SOFTWARE SENDINGS
            order-software-sending(v-for="sSending in model.softwareSendings" :key="sSending['@id']" :value="sSending" @input="setSoftwareSending")

</template>
